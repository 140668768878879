@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@font-face {
  font-family: 'SquadaOne';
  src: local('SquadaOne'), url(./assets/SquadaOne-Monospace.otf) format('opentype');
}

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.6);
}

/* Sidebar */

.modal-sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.6);
  transform: translateX(-100%);
  transition: transform 2000ms ease-in-out;
}
.modal-sidebar-overlay--after-open {
  transform: translateX(0%);
}
.modal-sidebar-overlay--before-close {
  transform: translateX(-100%);
}

.modal-sidebar {
  position: absolute;
  top: 0px;
  left: auto;
  right: 0px;
  bottom: 0px;
  border: 1px solid #ccc;
  background: #fff;
  max-width: 40%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  outline: none;
  padding: 20px;
}
@media screen and (max-width: 678px) {
  .modal-sidebar {
    max-width: 80%;
  }
}
